@import "../../styles/colors.scss";

main {
  background-color: $gray100;
  padding: 30px 0;
  &.no-result {
    min-height: calc(100vh - 128px - 52px);
  }
}

.row {
  display: flex;
}

.col {
  width: 25%;
}

.ol {
  padding-left: 18px;
}

.form-btn-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  @media only screen and (max-width: 768px) {
    flex-direction: column;
    align-items: flex-start;
  }
}

.btn-wrap {
  display: flex;
  justify-content: flex-end;
  padding: 20px 0;
  @media only screen and (max-width: 991px) {
    justify-content: center;
    padding: 40px 0 60px 0;
  }
}

.table {
  background-color: $white;
  border-radius: 6px;
  overflow: hidden;
}

.table-body {
  .row {
    &:nth-child(odd) {
      background-color: $gray100;
    }
  }
  .col {
    display: flex;
    align-items: center;
    line-height: 1.4;
    padding: 12px 15px;
    @media only screen and (min-width: 992px) and (max-width: 1100px) {
      padding: 12px 6px;
    }
  }
}

.no-result-container {
  width: 100%;
  padding: 40px;
  text-align: center;
  font-size: 28px;
  @media only screen and (max-width: 991px) {
    padding: 0;
    margin: 70px 0 20px 0;
  }
}
