.ttu {
  text-transform: uppercase;
}

.tdu {
  text-decoration: underline;
}

.d-flex {
  display: flex;
}

.d-i-block {
  display: inline-block;
}

.justify-end {
  justify-content: flex-end;
}

.flex-column {
  flex-direction: column;
}

.mt10 {
  margin-top: 10px;
}

.mt20 {
  margin-top: 20px;
}
