@import "./colors.scss";

html,
body {
  font-size: 1rem;
}

body {
  &:lang(en) {
    font-family: "Mulish", sans-serif;
  }
  &:lang(hy) {
    font-family: "Noto Sans Armenian", sans-serif;
  }
}

.mulish {
  font-family: "Mulish", sans-serif !important;
}
.noto {
  font-family: "Noto Sans Armenian", sans-serif !important;
}

button {
  border: none;
  background-color: transparent;
  padding: 0;
  cursor: pointer;
  font-family: inherit;
}

.c-container {
  width: 100%;
  padding: 0 150px;
  @media only screen and (max-width: 1199px) {
    padding: 0 16px;
  }
  @media only screen and (max-width: 768px) {
    padding: 0 40px;
  }
  @media only screen and (max-width: 576px) {
    padding: 0 33px;
  }
}

.container {
  width: 95%;
  max-width: 1660px;
  margin: 0 auto;
  @media only screen and (max-width: 1800px) {
    max-width: 1440px;
  }
  @media only screen and (max-width: 1440px) {
    max-width: 1140px;
  }
}

.btn {
  display: inline-block;
  height: 40px;
  line-height: 40px;
  padding: 0 16px;
  border-radius: 4px;
  font-size: 16px;
  font-weight: 600;
  text-decoration: none;
  transition: all 0.2s linear;
  &:hover {
    opacity: 0.8;
  }
  &.dark {
    background-color: $aubergine;
    color: $white;
  }
  &.white {
    background-color: $white;
    color: $black300;
    display: inline-flex;
    justify-content: space-between;
    border: 1px solid rgba(0, 0, 0, 0.15);
  }
  .icon {
    display: inline-block;
    margin-inline-end: 12px;
    width: 20px;
  }
}
