.fz12 {
  font-size: 12px;
}
.fz14 {
  font-size: 14px;
}
.fz16 {
  font-size: 16px;
}
.fz18 {
  font-size: 18px;
}
.fz28 {
  font-size: 28px;
}
.fz36 {
  font-size: 36px;
}

.fw600 {
  font-weight: 600;
}

.lh18 {
  line-height: 1.8;
}

.c-black {
  color: $black;
}
.c-black100 {
  color: $black100;
}
.c-black200 {
  color: $black200;
}
.c-black300 {
  color: $black300;
}
.c-black400 {
  color: $black400;
}

.bg-aubergine {
  background-color: $aubergine;
}
