@import "../../styles/colors.scss";

.footer {
  background-color: $white;
  padding: 10px 0;
  .link {
    display: flex;
    align-items: center;
    justify-content: center;
    text-decoration: none;
  }
  .sdc-logo {
    margin-inline-end: 10px;
    display: inline-block;
  }
}
