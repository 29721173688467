@import "reset.scss";
@import "colors.scss";
@import "mixins.scss";
@import "zIndexes.scss";
@import "typography.scss";
@import "common.scss";
@import "utils.scss";

form {
  width: 100%;
  max-width: 374px;
  flex: none;
  position: relative;

  @media only screen and (max-width: 768px) {
    max-width: initial;
    margin-top: 40px;
  }
}

.input-wrapper {
  position: relative;
  width: 100%;

  .search-input {
    height: 40px;
    width: 100%;
    border-radius: 4px;
    border: 1px solid $black100;
    padding: 2px 20px 0 44px;
    transition: all 0.2s ease;
    font-family: "Mulish", sans-serif;

    &::placeholder {
      color: $black200;
      font-size: 16px;
      font-family: "Mulish", sans-serif;
      font-weight: 300;
    }

    &:focus {
      outline: none;
      border-color: $black400;
    }
  }

  .search-icon {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: 12px;
    width: 22px;
  }
}

.search-result-text {
  text-align: right;
  position: absolute;
  right: 2px;
  bottom: -27px;

  @media only screen and (max-width: 768px) {
    right: initial;
    left: 0;
    bottom: -24px;
  }
}

.table {
  border: 1px solid rgba(0, 0, 0, 0.15);
  margin-top: 40px;

  @media only screen and (max-width: 768px) {
    &.lg {
      display: none;
    }
  }
}

.table-header {
  height: 45px;
}

.table-body {
  max-height: 525px;
  overflow-y: auto;
}

.col-label {
  display: flex;
  padding: 0 15px;

  @media only screen and (min-width: 992px) and (max-width: 1100px) {
    padding: 0 6px;
  }
}

.sort-btn {
  background: transparent;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  font-size: 14px;
  font-weight: 600;
  color: $black;

  &.up {
    .arrows-wrap {
      svg:first-child path {
        fill: $red;
      }
    }
  }

  &.down {
    .arrows-wrap {
      svg:last-child path {
        fill: $red;
      }
    }
  }
}

.table-mobile {
  display: none;
  border: 1px solid $black100;
  max-height: 700px;
  overflow-y: auto;
  margin-top: 20px;

  @media only screen and (max-width: 768px) {
    display: block;
    margin-top: 62px;
  }

  .row {
    display: flex;

    &:nth-child(even) {
      padding: 16px 0 20px 0;
      background-color: $white;

      div {
        border-bottom: 1px solid $gray100;
      }
    }

    &:nth-child(odd) {
      background-color: $gray100;

      div {
        border-bottom: 1px solid $white;
      }
    }
  }

  .thead,
  .tbody {
    width: 45%;
    flex-grow: 1;

    div {
      padding: 5px 14px;
      min-height: 24px;
    }
  }
}

// Mailchimp overrides
#mc_embed_signup {
  margin-bottom: 0;
  display: flex;
  justify-content: center;
  text-align: center;
}

#mc_embed_signup .helper_text {
  background-color: transparent;
}

#mc_embed_signup form {
  padding: 20px;
  margin: 0;
  max-width: 900px;
}

#mc_embed_signup h2 {
  margin: 0 0 20px 0;
  line-height: 1.3;
  text-align: center;

  @media only screen and (max-width: 550px) {
    font-size: 18px;
  }
}

#mc_embed_signup .indicates-required {
  margin: 0;
  max-width: 800px;
}

#mc_embed_signup .mc-field-group label {
  margin-bottom: 8px;
}

.optionalParent .foot {
  @media only screen and (max-width: 550px) {
    display: flex !important;
    flex-direction: column !important;
    gap: 30px;
  }
}
