@import "../../styles/colors.scss";

.header {
  height: 100px;
  display: flex;
  align-items: center;
  box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.1);
  position: relative;
  .h-container {
    width: 100%;
    padding: 0 50px;
    display: flex;
    justify-content: space-between;
    @media only screen and (max-width: 1199px) {
      padding: 0 16px;
    }
    @media only screen and (max-width: 768px) {
      padding: 0 25px;
    }
    @media only screen and (max-width: 576px) {
      padding: 0 18px;
    }
  }
  .logo-container {
    width: 100%;
    display: flex;
    align-items: flex-start;
  }
  .logo {
    width: 100%;
    max-width: 30px;
  }
  .texts {
    margin: 0 15px;
    .title {
      @media only screen and (max-width: 780px) {
        font-size: 26px;
      }
      @media only screen and (max-width: 600px) {
        font-size: 20px;
      }
      &:lang(hy) {
        @media only screen and (max-width: 480px) {
          font-size: 15px;
        }
      }
    }
    .subtitle {
      margin-left: 2px;
      margin-top: 8px;
      @media only screen and (max-width: 400px) {
        font-size: 14px;
      }
      &:lang(hy) {
        font-size: 14px;
        @media only screen and (max-width: 480px) {
          font-size: 11px;
        }
      }
    }
  }
  .change-lang {
    &:hover {
      color: $red;
    }
  }
}
